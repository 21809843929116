import { Box, Typography } from '@mui/material'
import { atgamesLogoLong } from '../assets'
import HomeImageList from '../components/HomeImageList'

const Home = () => {
  return (
    <Box sx={{height: '100%', overflow: 'auto'}}>
        <br />
        <img src={atgamesLogoLong} height={50}/>
        <br />
        <HomeImageList />
        <Typography sx={{color: '#ccc', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 15}}>
            Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
        </Typography>
    </Box>
  )
}

export default Home