import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const WhiteList = ({ text }: { text: string }) => (
  <ListItem disablePadding sx={{ color: 'white', fontWeight: 500 }}>
    <ListItemIcon sx={{ color: 'white' }}>
      <HorizontalRuleIcon />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

export default WhiteList;