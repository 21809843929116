import './App.css';
import ResponsiveAppBar from './components/ResponsiveAppbar';
import Box from '@mui/material/Box';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import About from './pages/About';
import Career from './pages/Career';
import NotFound from './pages/NotFound';
import DataAnalyst from './pages/JobDescriptions/DataAnalyst';
import MarketingManager from './pages/JobDescriptions/MarketingManager';
import SeniorWebFE from './pages/JobDescriptions/SeniorWebFE';
import LiveOpsManager from './pages/JobDescriptions/LiveOpsManager';
import AssistantGameMerchandiser from './pages/JobDescriptions/AssistantGameMerchandiser';
import ADS from './pages/ADS';
import SystemAdministrator from './pages/JobDescriptions/SystemAdministrator';


function App() {
  return (
    <Router>
      <Box className="App"
        sx={{ height: '100%'}}>
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}>
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/ads" element={<ADS />} />
            <Route path="/career" element={<Career />} />
            <Route path="/career/data-analyst" element={<DataAnalyst />} />
            <Route path="/career/marketing-manager" element={<MarketingManager />} />
            <Route path="/career/senior-web-frontend-developer" element={<SeniorWebFE />} />
            <Route path="/career/liveops-manager" element={<LiveOpsManager />} />
            <Route path="/career/assistant-game-merchandiser" element={<AssistantGameMerchandiser />} />
            <Route path="/career/marketing-assistant-sales-and-marketing-coordinator" element={<MarketingManager />} />
            <Route path="/career/system-administrator-contract" element={<SystemAdministrator />} />
            <Route path="*" element={<NotFound />} />
            {/* Add more routes as needed */}
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
