import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../../assets'
import WhiteList from '../../components/WhiteList'


const AssistantGameMerchandiser = () => {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    height: 'auto',
                    width: .5,
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    border: 2,
                    borderColor: 'black',
                    margin: 'auto',
                    mt: 5,
                    overflow: 'auto',
                }}
            >
                <Box
                    component="img"
                    src={map} alt="atgamesLogo"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        top: 0,
                        left: 0,
                        width: '100%', // Set the width of the img Box to 100% of the parent Box
                        height: 'auto', // Maintain aspect ratio
                        position: 'relative', // Position the img Box absolutely within the parent Box
                        mb: 2
                    }}
                />
                <img src={atgamesLogoLongBlack} height={30} width={'auto'} />
                <Typography variant='h1' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 32 }}>
                    Assistant Games Merchandiser
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    Location: El Segundo, California, United States
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    About Us
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    AtGames (www.atgames.net) is an international video game products company that combines over a decade of experience with innovative ideas and groundbreaking streaming technology.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Responsibilities
                </Typography>
                <List sx={{ ml: 4, mr: 4 }}>
                    <WhiteList text="Own merchandising / operational aspects of setting up new game titles in internal systems" />
                    <WhiteList text="Complete assigned competitive research on pricing as well as unique offers/value provided by external platforms" />
                    <WhiteList text="Assist in the development of price promotions based on competitive analysis. Enter related promotional data into internal systems" />
                    <WhiteList text="Maintain a calendar of upcoming game releases and work with publishers to ensure that new content is submitted on a timely basis" />
                    <WhiteList text="Engage with game publishers to provide support in sharing sales data" />
                    <WhiteList text="Own the submission of game title keys and related metadata into company workflows" />
                    <WhiteList text="Develop process flows using PowerPoint or Visio to address internal process gaps" />
                    <WhiteList text="Partner with Asia-based operations team to maintain and update game title records" />
                    <WhiteList text="Deliver weekly operations updates to the management team" />
                    <WhiteList text="Provide ad hoc analytical support using Excel and/or other analytical tools" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Qualifications and Skills
                </Typography>
                <List sx={{ ml: 4, mr: 4 }}>
                    <WhiteList text="Bachelor’s degree" />
                    <WhiteList text="Intermediate Excel skills (Pivot Tables/V-LookUps/Formulas)" />
                    <WhiteList text="Passionate about video games (especially PC) and knowledgeable about the industry" />
                    <WhiteList text="Self-motivated and able to work independently" />
                    <WhiteList text="Detail-oriented and well-organized" />
                    <WhiteList text="Minimal travel required" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Pluses
                </Typography>
                <List sx={{ ml: 4, mr: 4 }}>
                    <WhiteList text="Avid gamer" />
                    <WhiteList text="Familiarity with JIRA" />
                    <WhiteList text="Prior experience working in the video game industry" />
                    <WhiteList text="Prior experience in sales reporting and forecasting" />
                    <WhiteList text="Prior experience in small company / start-up environments" />
                </List>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    AtGames has the unique culture of being an established company with exciting new initiatives that give us a highly dynamic startup vibe. Our team is flexible and energetic, with a strong work ethic and a passion for gaming and for our products. You’ll fit in very well if you appreciate being given an opportunity to give feedback and help grow exciting new digital products, as well as maintain existing ones, beyond the scope of your role. Conveniently located by LAX in El Segundo, CA , with easy access to multiple freeways and many lunch options!
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Job Type
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    Full-time
                </Typography>
                <Divider
                    sx={{ margin: 4 }}
                    color='white'
                />
                <Typography sx={{ color: 'white', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 10, mt: 4 }}>
                    Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
                </Typography>
            </Box>
        </Box>
    )
}

export default AssistantGameMerchandiser