import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

function createData(
  name: string,
  aoi: string,
  location: string,
  href: string,
) {
  return { name, aoi, location, href};
}

const rows = [
  createData('Data Analyst (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/data-analyst'),
  createData('Marketing Manager (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/marketing-manager'),
  createData('Senior Web Front-end Developer (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/senior-web-frontend-developer'),
  createData('Live Operations Manager (Local Candidates Only)', 'Operations', 'El\u00A0Segundo, CA', '/career/liveops-manager'),
  createData('Assistant Games Merchandiser (Local Candidates Only)', 'Merchandising', 'El\u00A0Segundo, CA', '/career/assistant-game-merchandiser'),
  createData('Marketing Assistant Sales and Marketing Coordinator (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/marketing-assistant-sales-and-marketing-coordinator'),
  createData('System Administrator - Contract (Local Candidates Only)', 'System', 'El\u00A0Segundo, CA', '/career/system-administrator-contract'),
]

export default function BasicTable() {
  return (
    <TableContainer component={Paper} sx={{width: .9, ml: 'auto', mr: 'auto', mt: 2}}>
      <Table sx={{ width: 'auto', minWidth: 400}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell align="right">Area of Interest</TableCell>
            <TableCell align="right">Location</TableCell>
            <TableCell align="right">Apply</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Box component={Link} to={row.href} className='aLinkBlack'>{row.name}</Box>
              </TableCell>
              <TableCell align="right">{row.aoi}</TableCell>
              <TableCell align="right" sx={{}}>{row.location}</TableCell>
              <TableCell
              align="right"
              sx={{width: 2}}>
                <Button
                    variant="contained"
                    color='success'
                    component={Link}
                    to={'mailto:career@atgames.net'}
                    sx={{
                        backgroundColor: 'rgba(130, 162, 29, 0.7)',
                        border: 1,
                        borderColor: 'black',
                        mt: 2
                    }}
                >Apply</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}