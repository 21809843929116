import { Box, Button, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const primary = purple[500]; // #f44336

export default function NotFound() {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: 0.3,
                    height: 'auto',
                    padding: 4,
                    margin: 'auto',
                    mt: 5,
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    border: 2,
                    borderColor: 'black'
                }}
            >
                <Typography variant="h1" style={{ color: 'white', fontFamily: 'monospace' }}>
                    404
                </Typography>
                <Typography variant="h6" style={{ color: 'white' }}>
                    The page you’re looking for doesn’t exist.
                </Typography>
                <Button
                    variant="contained"
                    color='success'
                    component={Link}
                    to={'/'}
                    sx={{
                        backgroundColor: 'rgba(130, 162, 29, 0.7)',
                        border: 1,
                        borderColor: 'black',
                        mt: 2
                    }}
                >Back Home</Button>
            </Box>
        </Box>
    );
}