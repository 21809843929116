import { Box, Typography } from '@mui/material'
import '../App.css'

const About = () => {
  return (
    <Box height='100%' sx={{ overflow: 'auto' }}>
      <Box
        sx={{
          height: 'auto', 
          width: .5, 
          justifyContent: 'center',
          backgroundColor: 'rgba(186, 218, 85, 0.7)',
          border: 2,
          borderColor: 'black',
          margin: 'auto', 
          mt: 5, 
          overflow: 'auto', 
          padding: 4
        }}
      >
        <Typography variant="h1" sx={{ fontWeight: 600, fontSize: 42, letterSpacing: 4, textTransform: 'uppercase', fontFamily: 'monospace', color: 'white'}}>About us</Typography>
        <Typography sx={{ mt: 1, color: 'white' }}>
          AtGames®, founded in 2001, is a veteran player in digital media and information<br />
          technology with a unique focus on interactive entertainment.<br />
          Over the years, our achievements have spanned retail products, digital distribution,<br />
          streaming technology, and more.<br />
          With locations in Los Angeles, Taipei, and Shenzhen, our globe-spanning office branches<br />
          synergize and evolve to produce products and services that stand out from the rest.
          <br /><br />
          Legends Arcade Family: <a href="https://www.atgames.net/arcades/contact/" className='aLink'>https://www.atgames.net/arcades/contact/</a><br />

          Flashback and Blast Products: <a href="mailto:info@atgames.net" className='aLink'>info@atgames.net</a><br />

          Media/Press Inquiries: <a href="mailto:pr@atgames.net" className='aLink'>pr@atgames.net</a><br />

        </Typography>
        <Typography sx={{color: 'white', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 4, mt: 4}}>
            Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Box>
    </Box>
  )
}

export default About