import { Box, Divider, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../assets'
import RolesTable from '../components/RolesTable'

const Career = () => {
  return (
    <Box sx={{height: "100%", overflow: 'auto'}}>
      <Box
        sx={{
          height: 'auto',
          width: .5,
          justifyContent: 'center',
          backgroundColor: 'rgba(186, 218, 85, 0.7)',
          border: 2,
          borderColor: 'black',
          margin: 'auto',
          mt: 5,
          overflow: 'auto',
        }}
      >
        {/*<img src={map} className='responsive-img'/>*/}
        <Box 
          component="img"
          src={map} alt="atgamesLogo"
          sx={{
            display: { xs: 'none', md: 'flex' },
            top: 0,
            left: 0,
            width: '100%', // Set the width of the img Box to 100% of the parent Box
            height: 'auto', // Maintain aspect ratio
            position: 'relative', // Position the img Box absolutely within the parent Box
            mb: 2
          }}
          />
        <img src={atgamesLogoLongBlack} height={30} width={'auto'}/>
        <Typography sx={{ color: 'white', fontWeight: 500, margin: 4}}>
          AtGames®, founded in 2001, is a veteran player in digital media and information technology with a
          unique focus on interactive entertainment. Over the years, our achievements have spanned retail
          products, digital distribution, streaming technology, and more.
          <br /><br />

          With locations in Los Angeles, Taipei, and Shenzhen, our globe-spanning office branches synergize and
          evolve to produce products and services that stand out from the rest. This distinctive spirit is embodied
          in our latest hit product, the Legends Ultimate, a full-size home arcade platform with innovative
          connected features.
          <br /><br />


          <b>AtGames of America, Inc. (El Segundo, CA)</b>
          <br /><br />


          <b>Product Management, Product Marketing, Technical Roles</b>
          <br /><br />

          AtGames (<a href='https://www.atgames.net/' className='aLink'>https://www.atgames.net/</a>) is looking for talented individuals to join the team at our El
          Segundo headquarters to help drive the development, marketing, and sales of the next generation of
          retro console, portable, and connected home arcade products. As a valued team member, you will use
          your background in consumer products and games hardware, product management, and/or technical
          expertise informed by a Computer Science or Electrical & Computer Engineering degree to succeed in
          this fun, fast-paced environment.<br />
          <br />

          If you feel your background is a fit, are up for the challenge, and have a passion for cutting edge home<br />
          consumer products with retro appeal, please send your resume or CV to <a href='mailto:career@atgames.net' className='aLink'>career@atgames.net</a>.<br />
        </Typography>
        <RolesTable />
        <Divider 
          sx={{margin: 4}} 
          color='white'
        />
        <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4}}>
          This document is proprietary and confidential. No part of this document may be disclosed in any manner to a third party without the prior written consent of AtGames.
        </Typography>
        <Typography sx={{color: 'white', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 10, mt: 4}}>
            Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Box>
    </Box>
  )
}

export default Career