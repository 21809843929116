import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../../assets'
import WhiteList from '../../components/WhiteList'


const LiveOpsManager = () => {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    height: 'auto',
                    width: .5,
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    border: 2,
                    borderColor: 'black',
                    margin: 'auto',
                    mt: 5,
                    overflow: 'auto',
                }}
            >
                <Box
                    component="img"
                    src={map} alt="atgamesLogo"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        top: 0,
                        left: 0,
                        width: '100%', // Set the width of the img Box to 100% of the parent Box
                        height: 'auto', // Maintain aspect ratio
                        position: 'relative', // Position the img Box absolutely within the parent Box
                        mb: 2
                    }}
                />
                <img src={atgamesLogoLongBlack} height={30} width={'auto'} />
                <Typography variant='h1' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 32 }}>
                    Live Operations Manager
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    Location: El Segundo, California, United States
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    About Us
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    AtGames (www.atgames.net) is an international video game products company that combines over a decade of experience with innovative ideas and groundbreaking streaming technology.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Job Description
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    We are currently looking for a Live Operations Manager to join our team in El Segundo to manage operations for our Legends Ultimate full-size and compact arcades.

                    The Operations team coordinates all of AtGame’s communication between customers and project stakeholders and provides comprehensive, real-time solutions for both parties. Our goal is to provide seamless and high-quality support to our customers. We do this by striving to provide the best service and optimal solutions for our customers.

                    As a Live Operations Manager, you will track multiple lines of communication to understand the issues at hand and drive towards the best possible solutions in a quick and efficient manner. Using various technologies, you will coordinate with multiple teams to communicate and craft complete solutions, and deliver clear and up-to-date information. As a part of this team, you will play a pivotal role in not only ensuring AtGames services run smoothly and effectively, but will have an impact on our customer’s enjoyment on a daily basis.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Responsibilities
                </Typography>
                <List sx={{ ml: 4, mr: 4 }}>
                    <WhiteList text="Oversee Live Ops team in the US" />
                    <WhiteList text="Managing communications—both written and verbal—from customers and stakeholders" />
                    <WhiteList text="Onboarding games and content to our services and setting up promotions" />
                    <WhiteList text="Triaging customer concerns, challenges, and escalated matters" />
                    <WhiteList text="Investigating, documenting, and reporting on various issues" />
                    <WhiteList text="Communicating performance feedback to stakeholders" />
                    <WhiteList text="Collaborating across multiple departments, often in real-time and on critical issues" />
                    <WhiteList text="Providing customer technical support" />
                    <WhiteList text="Contributing to the growth, improvement, and innovation of Live Operations" />
                    <WhiteList text="Travel to support customers on site" />
                    <WhiteList text="A college degree is required. People who love to organize and are attentive to details will do especially well in this position. If you are adaptable and eager to learn, AtGames will teach you!" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Qualifications and Skills
                </Typography>
                <List sx={{ ml: 4, mr: 4 }}>
                    <WhiteList text="3-5 years experience of management experience" />
                    <WhiteList text="3-5 years working in Live Ops" />
                    <WhiteList text="Exceptional phone skills and verbal communication in order to clearly and effectively converse with populations who may have limited understanding about technologies" />
                    <WhiteList text="Ability to resolve complex and time-sensitive problems using both established protocol and creative thinking, as necessary" />
                    <WhiteList text="Ability to self-manage, multitask and prioritize one’s work in a fast-paced and high-pressure environment" />
                    <WhiteList text="Ability to rapidly and simultaneously use multiple technology tools to enable real-time information-gathering and communication" />
                    <WhiteList text="Superb writing skills and experience communicating via email and digital messaging tools" />
                    <WhiteList text="Demonstrated excellence in managing work relationships" />
                    <WhiteList text="Naturally compassionate and empathetic listening skills" />
                    <WhiteList text="Strong time management skills (We juggle a lot!)" />
                    <WhiteList text="Adaptable and fast learner who is able to self-regulate and remain calm under pressure" />
                    <WhiteList text="Highly organized, detail-oriented, and precise in execution" />
                    <WhiteList text="Growth-oriented team player with initiative and enthusiasm" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mr: 4, ml: 4, fontSize: 26 }}>
                    Job Type
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mr: 4, ml: 4, }}>
                    Full-time
                </Typography>
                <Divider
                    sx={{ margin: 4 }}
                    color='white'
                />
                <Typography sx={{ color: 'white', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 10, mt: 4 }}>
                    Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
                </Typography>
            </Box>
        </Box>
    )
}

export default LiveOpsManager