import { Box, ImageList, ImageListItem } from '@mui/material'
import { Image1, Image2, Image4, Image5, Image7, Image9 } from '../assets';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function srcset(image: string, maxWidth: number, maxHeight: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${maxWidth * cols}&h=${maxHeight * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${maxWidth * cols}&h=${maxHeight * rows}&fit=crop&auto=format&dpr=2 2x`,
    };
}

const HomeImageList = () => {
    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }

    const getColumns = (width: number) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 2
        } else if (width < breakpoints.lg) {
            return 3
        } else if (width < breakpoints.xl) {
            return 5
        } else {
            return 5
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const itemData = [
        {
            img: Image1,
            title: 'Legends Ultimate',
            href: 'https://atgames.net/arcades',
            rows: 1,
            cols: columns/5,
        },
        {
            img: Image2,
            title: 'ArcadeNet',
            href: 'https://atgames.net/arcadenet',
            rows: 1,
            cols: columns/5,
        },
        {
            img: Image4,
            title: 'Atgames.us',
            href: 'https://www.atgames.us',
            rows: 1,
            cols: columns/5,
        },
        {
            img: Image7,
            title: 'Blash',
            href: 'https://blast.atgames.net/',
            rows: 1,
            cols: columns/5,
    
        },
        {
            img: Image9,
            title: 'AtGames Digital Services',
            href: 'https://www.atgames.net/ads',
            rows: 1,
            cols: columns/5,
    
        },
        {
            img: Image5,
            title: 'About Us',
            href: '/about',
            rows: 1,
            cols: columns,
        },
        /*{
            img: Image6,
            title: 'About Us',
            href: '/about',
            rows: 1,
            cols: 5,
        },*/
    
    ];

    return (
        <Box>
            <ImageList
                sx={{ width: .8, height: .8, ml: "auto", mr: "auto", mt: 3 }}
                variant="quilted"
                //cols={5}
                cols={columns}
                rowHeight={"auto"}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}
                        sx={{ maxHeight: 400 }}
                        component={Link}
                        to={item.href}>
                        <img
                            {...srcset(item.img, 150, 200, item.rows, item.cols)}
                            alt={item.title}
                            loading="lazy"
                            className="responsive-img scale-down"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    )
}

export default HomeImageList