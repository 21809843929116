import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';

export default function OtherLinks() {
    const subPages = [
        { name: 'Legends Ultimate', path: '/arcades' },
        { name: 'AZF', path: 'https://www.atgames.us/' },
        { name: 'E-Store', path: 'https://www.atgames.us/' },
        { name: 'Blast', path: 'https://blast.atgames.net/'},
        { name: 'ADS', path: '/ads'},
        { name: 'ArcadeNet', path: '/arcadenet'},
    ];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenURL = (websiteUrl: string | URL | undefined) => {
        setAnchorEl(null);

        // Open the website in a new tab or window
        window.open(websiteUrl, '_self');
    };


    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    my: 2,
                    color: 'white',
                    display: 'block',
                    borderRadius: 0,
                }}
            >
                Other Links
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {subPages.map((subpage) => (
                    <MenuItem
                        key={subpage.name}
                        onClick={() => handleOpenURL(subpage.path)}
                    >
                        {subpage.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}